<template>
  <div class="layout relative">
    <header class="flex justify-between items-center">
      <div class="logo">
        <img src="../assets/红黑 1@2x.png" alt="" srcset="">
      </div>
      <div class="nav flex" style="margin-right:15%;">
        <div class="nav-part" :class="{navac:currentNav=='home'}">
          <router-link to="/" @click.native="currentNav='home'">{{ $t('message.nav.home') }}</router-link> 
          </div>
        <div class="nav-part" :class="{navac:currentNav=='develop'}">
          <router-link to="/develop" @click.native="currentNav='develop'">{{ $t('message.nav.compony') }}</router-link> 
          </div>
        <div class="nav-part" :class="{navac:currentNav=='about'}">
          <router-link to="/about" @click.native="currentNav='about'">{{ $t('message.nav.aboutUs') }}</router-link>
        </div>
        <div class="nav-part" :class="{navac:currentNav=='contact'}">
          <router-link to="/contact" @click.native="currentNav='contact'">{{ $t('message.nav.contactUs') }}</router-link>
        </div>
      </div>
      <div class="transBox">
        <el-button type="text" @click="transLang" class="transBtn">
          <img src="../assets/transIcon.png" width="26"  style="display: inline-block;vertical-align: bottom;"/>
          {{ $i18n.locale=='zh_CN'?'English':'简体中文' }}
        </el-button>
      </div>
    </header>
    <router-view></router-view>
    <footer class="flex flex-col items-center relative">
      <div class="absolute" style=" top:40px;left:50px;">
        <img src="../assets/红黑 1@2x.png" style="width:110px;" alt="" srcset="">
      </div>
      <div class="flex justify-between" style="width:100%;padding:0px 10%;">
        <div class="part">
          <div class="tit relative">
            {{ $t('message.footer.about.title') }}
            <span class="mark"></span>
          </div>
          <div class="content">
            {{ $t('message.footer.about.content') }}
          </div>
        </div>
        <!-- <div class="part" style="max-width:250px;">
          <div class="tit relative">
            友情链接
            <span class="mark"></span>
          </div>
          <div class="flex justify-between">
            <div>
              <div class="py-4">友好国际旅行社</div>

              <div class="py-4">金川实业有限公司</div>
            </div>
            <div>
              <div class="py-4">人才招聘</div>
              <div class="py-4">常见问题</div>
              <div class="py-4">获取帮助</div>
            </div>
          </div>
        </div> -->
        <div class="part" style="max-width:305px;">
          <div class="tit relative">
            {{ $t('message.footer.contact.title') }}
            <span class="mark"></span>
          </div>
          <div>
            <div class="flex">
              <div>{{$t('message.footer.contact.subTitle1')}}：</div>
              <div class="flex-1 pb-5">{{$t('message.footer.contact.content1')}}</div>
            </div>
            <div class="flex">
              <div>{{$t('message.footer.contact.subTitle2')}}：</div>
              <div class="flex-1 pb-5">+86-13564699538</div>
            </div>
            <div class="flex">
              <div>{{$t('message.footer.contact.subTitle3')}}：</div>
              <div class="flex-1 pb-5">80337761@qq.com</div>
            </div>
          </div>
        </div>
        <div class="part" style="max-width:180px;">
          <div class="tit relative">
            {{ $t('message.footer.time.title') }}
            <span class="mark"></span>
          </div>
          <div>
            {{ $t('message.footer.time.title') }} 9:00-18:00
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="text-base leading-8">
        <!-- <span class=" mr-10">COPYRIGHT</span>
        <span>2017.LEDOT ALL RIGHTS RESERVED.</span>
        <span class="text-red-700">上海红檀</span> -->
      </div>
      <div class="text-red-700">
        <a href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">沪ICP备18022990号-3</a>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  data(){
    return {
      currentNav:'home',
    }
  },
  methods:{
    transLang(){
      console.log(this.$i18n.locale)
      let locale = this.$i18n.locale;
      if(locale === 'zh_CN'){
        this.$i18n.locale = 'en_US';
      }
      if(locale === 'en_US'){
        this.$i18n.locale = 'zh_CN';
      }
    }
  }
}
</script>
<style lang='less' scoped>
.layout{
  min-width: 1366px;
  header{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    z-index: 999;
    .nav{
      .nav-part{
        margin: 0px 40px;
        display: inline-block;
        height: 34px;
        font-size: 28px;
        font-family: Inter-Regular, Inter;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 33px;
      }
      .navac{
        color: #B02923FF;
      }
    }
    .logo img{
      width: 213px;
    }
    .transBox{
      position: absolute;
      top:5px;
      right:15px;
      .transBtn{
        font-size: 25px;
        color: #fff;
      }
    }
  }
  footer{
    padding: 140px 0px 75px;
    color: #FFFFFF;
    background: rgba(0,0,0,0.9);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    .part{
      box-sizing: content-box;
      max-width: 400px;
      flex:1;
      text-align: left;
      padding: 0px 40px;
      .tit{
        font-size: 24px;
        margin-bottom: 60px;
        .mark{
          display: inline-block;
          width: 30px;
          height: 5px;
          background: #B02922;
          position: absolute;
          bottom: 0px;
          left:0px;
        }
      }
      .content{
        line-height: 24px;
        letter-spacing: 1px;
        font-size: 16px;  
      }
    }
    .line{
      width: 70%;
      height: 1px;
      background: #D9D9D9;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      margin: 50px 0px 10px;
    }
  }
  @media screen and (max-width: 1366px){
    overflow: hidden;
  }
}
</style>
